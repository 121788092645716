<template>

    <div class="grower_item_solution">

      <div class="user">
        <NuxtLink class="grower " :to="data.link">
          <div class="avatar">
            <img loading="lazy" class="ava" :alt="data.name" :src="data.avatar_small">
            <div :class="'user_range u_range_' + data.status"></div>
          </div>
          <div class="name">{{ data.name }}</div>
          <div class="status">{{ UserStatusTr[data.status] ? $t(UserStatusTr[data.status]) : '' }}</div>
        </NuxtLink>
      </div>

      <div class="graph">
        <div class="graph_width" :style="'width:' + Math.round(data.cnt_vote*100/max_votes) + '%'">
          <div class="likes"><span class="hint_likes">{{ data.cnt_vote }} {{ $plurals.get('solution', data.cnt_vote) }}</span></div>
          <div class="line" :style="'background-color:'  + bgColor">&nbsp;</div>
        </div>
      </div>

    </div>

</template>

<script setup lang="ts">

import { UserStatusTr } from '@/types/user';

import { ColorPalette } from '@/types/other';

const { $constants } = useNuxtApp();

const props = defineProps({
  data: {
    type: Object
  },
  max_votes: {
    type: [String, Number]
  },
  position: {
    type: Number
  }
})

const max_votes = ref(0);

const bgColor = computed(() => {
  let pos = props.position ?? 2;
  return ColorPalette[pos % ColorPalette.length];
})




</script>

<style scoped>
 
 


/* CONTENT */



.mg_list{ 
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


.grower_item_solution{
    display: -webkit-flex;
    display: flex;
    width: 100%;
}
.grower_item_solution .user{ 
    width: 200px;
}
.grower_item_solution .user .grower{
    display: inline-block;
    margin-bottom: 12px;
    width: 90%;
}
.grower_item_solution .user .grower .avatar{
    max-width: 80px;
    display: inline-block;
    float: left;
    margin-right: 10px;
    position: relative;
}
.grower_item_solution .user .grower .avatar .ava{
    border-radius: 150px; 
    width: 50px;
    display: inline-block;
    width: 45px;
    vertical-align: top;
    position: relative; 
}
.grower_item_solution .user .grower .avatar .user_range{
    font-size: 1.6rem;

    margin-right: 0px;
    position: absolute;
    right: 0px;
    top: 24px;
}
.grower_item_solution .user .grower .name{
    font-size: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 2px;
    color: var(--un-text-color);
}
.grower_item_solution .user .grower .status{
    color: gray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.grower_item_solution .graph{
    width: calc(100% - 200px);
    text-align: right;
}
.grower_item_solution .graph .line{
    background-color: #dddede;
    color: white;
    padding: 2px 10px;
    border-radius: 3px;
}
.grower_item_solution .graph .likes{
    margin-top: 4px;
}
.grower_item_solution .graph .likes .icon-leaf-like{
    color: var(--un-primary-back-color);
    font-size: 1.3rem;
    vertical-align: sub;
}
.grower_item_solution .graph .likes .hint_likes{
}

.grower_item_solution  .graph_width{
   
    min-width: 100px;
    transition: all 0.5s ease-in-out;
}
  


</style>
